.wrapper-chat-base {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 20px;
  height: 100%;
  overflow: hidden;

  .chat-title {
    margin: 10px 0;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }

  .chat-list {
    flex-grow: 1;
    padding-top: 10px;
    margin-bottom: 20px;
    overflow: scroll;

    .chat-item {
      display: flex;
      margin-bottom: 20px;

      .input-cursor {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #fff;
        margin-left: 3px;
        vertical-align: middle;
        line-height: 28px;
        margin-bottom: 3px;

        &.pending {
          animation: cursorScale 1.5s infinite;
        }

        @keyframes cursorScale {
          0% {
            scale: 1;
          }
          50% {
            scale: 1.2;
          }
          100% {
            scale: 1;
          }
        }
      }

      .avatar {
        flex-shrink: 0;
        margin-right: 15px;
        width: 30px;
        height: 30px;
        font-size: 12px;
        background-color: #3E8CE2;
      }

      .wrapper-message {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        .nickname {
          color: #fff;
          font-size: 20px;
          font-weight: 800;
          margin-bottom: 10px;
        }

        .message {
          color: #FFF;
          font-size: 18px;
          font-weight: 300;
          line-height: 28px;
          user-select: text;
        }

        .message-image {
          width: 400px;
          height: 400px;
          border-radius: 8px;
        }

        .message-tips {
          display: flex;
          align-items: center;
          color: #3D404A;
          font-size: 12px;
          margin-top: 5px;

          .icon-copy {
            font-size: 12px;
            margin-left: 10px;
            cursor: pointer;

            &:hover {
              color: #fff;
            }
          }

          .icon-copyed {
            font-size: 12px;
            margin-left: 10px;
          }

          .tips-item:hover {
            color: #fff;
          }

          .split {
            width: 1px;
            height: 8px;
            border-left: 1px solid #3D404A;
            margin: 0 5px;
          }
        }
      }
    }
  }

  .wrapper-input {
    position: relative;
    flex-shrink: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 15px;
    border-radius: 8px;
    background-color: #1f2028;
    border: 1px solid rgba($color: #fff, $alpha: 0.85) !important;

    .button-reach-bottom {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 30px;
      cursor: pointer;
    }

    &:hover {
      border: 1px solid rgba($color: #fff, $alpha: 1) !important;
    }

    .message-input {
      background-color: transparent;
      border: none;
      outline: none;
      resize: none;
      width: 100%;
      color: #fff;
      font-size: 18px;
    }

    .message-button {
      flex-shrink: 0;
      align-self: flex-end;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      padding: 0;
      margin-left: 5px;
      background-color: #3D404A;
      border-radius: 8px;
      opacity: 0.5;

      .icon-send {
        color: #fff;
      }

      &.active {
        opacity: 1;
        background-color: #3E8CE2;
        cursor: pointer;
      }
    }
  }
}

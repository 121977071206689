.audio-bubble {
  border-radius: 8px !important;
  background-color: rgba($color: #3D404A, $alpha: 0.5) !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  cursor: pointer;

  &.playing {
    .duration {
      color: #fff;
    }

    .wave {
      background-color: #fff;
      animation: waveAnimation 1s ease-in-out infinite;
    }
  }

  .duration {
    margin-left: 8px;
    color: rgba($color: #fff, $alpha: 0.5);
  }

  .wrapper-wave {
    display: flex;
    align-items: center;
    height: 20px;
  }

  @keyframes waveAnimation {

    0%,
    40%,
    100% {
      transform: scaleY(1);
    }

    20% {
      transform: scaleY(2.5);
    }
  }

  .wave {
    display: inline-block;
    background-color: rgba($color: #fff, $alpha: 0.5);
    width: 5px;
    height: 8px;
    margin: 0 2px;
    border-radius: 2px;
  }

  .wave:nth-child(2),
  .wave:nth-child(6) {
    animation-delay: -0.7s;
  }

  .wave:nth-child(3),
  .wave:nth-child(5) {
    animation-delay: -0.8s;
  }

  .wave:nth-child(4) {
    animation-delay: -0.9s;
  }
}


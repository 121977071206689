@import "./tailwind.scss";

body,
html {
  -webkit-overflow-scrolling: touch;
}

body {
  min-height: 100%;
  font-family: PingFangSC;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'PingFang SC', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*::before {
  box-sizing: border-box;
}

*::after {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
button {
  border: none;
  outline: none;
}


.App {
  display: flex;
  align-items: stretch;
  height: 100vh;

  .sidebar {
    width: 85px;
    background-color: #14151b;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sidebar-item {
      width: 71px;
      height: 71px;
      margin-bottom: 7px;
      cursor: pointer;

      &:hover {
        background-color: #1f2028;
      }
    }
  }
}

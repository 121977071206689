@tailwind utilities;

@layer utilities {
  .border-radius-s {
    border-radius: 5px;
  }
  .border-radius-l {
    border-radius: 10px;
  }
}
